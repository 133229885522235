import axios from 'axios'

const apiURL = 'https://nuproxatech.com/api/v1/'
//const apiURL = 'https://testing.nuproxatech.com/api/v1/'

const client = axios.create({
  baseURL: apiURL,
  json: true
})

export default {
  async execute (method, resource, data) {
    // inject the accessToken for each request
    let accessToken = localStorage.getItem("userToken")
    const req = await client({
      method: method,
      url: resource,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        UserId: localStorage.getItem("userId"),
        WebApp: true
      }
    })
    return req.data
  },
  getModules () {
    return this.execute('get', '/modules')
  },
  //users
  login (data) {
    return this.execute('post', '/login', data)
  },
  recover (data) {
    return this.execute('post', '/recover', data)
  },
  getUsers () {
    return this.execute('get', '/users')
  },
  getUsersType () {
    return this.execute('get', '/userstype')
  },
  getUser (id) {
    return this.execute('get', `/users/${id}`)
  },
  registerUser (data) {
    return this.execute('post', '/users', data)
  },
  editUser (id, data) {
    return this.execute('put', `/users/${id}`, data)
  },
  editUserPassword (id, data) {
    return this.execute('patch', `/users/${id}`, data)
  },
  deleteUser (id) {
    return this.execute('delete', `/users/${id}`)
  },
  //fields
  getFields (id) {
    return this.execute('get', `/fields/${id}`)
  },
  registerField (data) {
    return this.execute('post', '/fields', data)
  },
  deleteField (id) {
    return this.execute('delete', `/fields/${id}`)
  },
  //states
  getStates () {
    return this.execute('get', '/states')
  },
  getState (id) {
    return this.execute('get', `/states/${id}`)
  },
  registerState (data) {
    return this.execute('post', '/states', data)
  },
  editState (id, data) {
    return this.execute('put', `/states/${id}`, data)
  },
  deleteState (id) {
    return this.execute('delete', `/states/${id}`)
  },
  //cities
  getCities () {
    return this.execute('get', '/cities')
  },
  getCity (id) {
    return this.execute('get', `/cities/${id}`)
  },
  registerCity (data) {
    return this.execute('post', '/cities', data)
  },
  editCity (id, data) {
    return this.execute('put', `/cities/${id}`, data)
  },
  deleteCity (id) {
    return this.execute('delete', `/cities/${id}`)
  },
  //countries
  getCountries () {
    return this.execute('get', '/countries')
  },
  getCountry (id) {
    return this.execute('get', `/countries/${id}`)
  },
  registerCountry (data) {
    return this.execute('post', '/countries', data)
  },
  editCountry (id, data) {
    return this.execute('put', `/countries/${id}`, data)
  },
  deleteCountry (id) {
    return this.execute('delete', `/countries/${id}`)
  },
  //measures
  getMeasures () {
    return this.execute('get', '/measures')
  },
  getMeasure (id) {
    return this.execute('get', `/measures/${id}`)
  },
  registerMeasure (data) {
    return this.execute('post', '/measures', data)
  },
  editMeasure (id, data) {
    return this.execute('put', `/measures/${id}`, data)
  },
  deleteMeasure (id) {
    return this.execute('delete', `/measures/${id}`)
  },
  //clients
  getAllStock () {
    return this.execute('get', '/stock')
  },
  getClients () {
    return this.execute('get', '/clients')
  },
  getClient (id) {
    return this.execute('get', `/clients/${id}`)
  },
  registerClient (data) {
    return this.execute('post', '/clients', data)
  },
  editClient (id, data) {
    return this.execute('put', `/clients/${id}`, data)
  },
  deleteClient (id) {
    return this.execute('delete', `/clients/${id}`)
  },
  //subsidiaries
  getSubsidiaries () {
    return this.execute('get', '/subsidiaries')
  },
  getSubsidiariesByClient (id) {
    return this.execute('get', `/clients/${id}/subsidiaries`)
  },
  getSubsidiary (id) {
    return this.execute('get', `/subsidiaries/${id}`)
  },
  registerSubsidiary (data) {
    return this.execute('post', '/subsidiaries', data)
  },
  editSubsidiary (id, data) {
    return this.execute('put', `/subsidiaries/${id}`, data)
  },
  deleteSubsidiary (id) {
    return this.execute('delete', `/subsidiaries/${id}`)
  },
  //providers
  getProviders () {
    return this.execute('get', '/providers')
  },
  getProvider (id) {
    return this.execute('get', `/providers/${id}`)
  },
  registerProvider (data) {
    return this.execute('post', '/providers', data)
  },
  editProvider (id, data) {
    return this.execute('put', `/providers/${id}`, data)
  },
  deleteProvider (id) {
    return this.execute('delete', `/providers/${id}`)
  },
  //products
  getProducts () {
    return this.execute('get', '/products')
  },
  getProductsByProvider (id) {
    return this.execute('get', `/providers/${id}/products`)
  },
  getProduct (id) {
    return this.execute('get', `/products/${id}`)
  },
  registerProduct (data) {
    return this.execute('post', '/products', data)
  },
  editProduct (id, data) {
    return this.execute('put', `/products/${id}`, data)
  },
  deleteProduct (id) {
    return this.execute('delete', `/products/${id}`)
  },
  //warehouses
  getWarehouses () {
    return this.execute('get', '/warehouses')
  },
  getWarehouse (id) {
    return this.execute('get', `/warehouses/${id}`)
  },
  getStock (id) {
    return this.execute('get', `/stock/${id}`)
  },
  getMovements (id) {
    return this.execute('get', `/movements/${id}`)
  },
  getMovementsProduct (type, id, dateStart, dateEnd) {
    return this.execute('get', `/movements/product/${type}/${id}?date_start=${dateStart}&date_end=${dateEnd}`)
  },
  getMovementsWarehouse (type, product, id, dateStart, dateEnd) {
    return this.execute('get', `/movements/warehouse/${type}/${product}/${id}?date_start=${dateStart}&date_end=${dateEnd}`)
  },
  registerWarehouse (data) {
    return this.execute('post', '/warehouses', data)
  },
  editWarehouse (id, data) {
    return this.execute('put', `/warehouses/${id}`, data)
  },
  deleteWarehouse (id) {
    return this.execute('delete', `/warehouses/${id}`)
  },
  //invoices
  getInvoices () {
    return this.execute('get', '/invoices')
  },
  getInvoicePreorder (id) {
    return this.execute('get', `/invoices/preorder/${id}`)
  },
  getInvoice (id) {
    return this.execute('get', `/invoices/${id}`)
  },
  getLastInvoiceId () {
    return this.execute('get', '/invoices/last/id')
  },
  registerInvoice (data) {
    return this.execute('post', '/invoices', data)
  },
  getInvoicesWith () {
    return this.execute('get', `/invoices/with`)
  },
  deleteInvoice (id) {
    return this.execute('delete', `/invoices/${id}`)
  },
  //preorders
  getRequests () {
    return this.execute('get', `/requests/orders/pending`)
  },
  deleteRequest (id, type) {
    return this.execute('delete', `/requests/${type}/${id}`)
  },
  getPreorder (id) {
    return this.execute('get', `/preorders/${id}`)
  },
  getPreorders () {
    return this.execute('get', '/preorders')
  },
  getPreordersWithout (status) {
    return this.execute('get', `/preorders/without/${status}`)
  },
  registerPreorder (data) {
    return this.execute('post', '/preorders', data)
  },
  editPreorder (id, data) {
    return this.execute('put', `/preorders/${id}`, data)
  },
  deletePreorder (id) {
    return this.execute('delete', `/preorders/${id}`)
  },
  //refunds
  getRefund (id) {
    return this.execute('get', `/refunds/${id}`)
  },
  getRefunds () {
    return this.execute('get', '/refunds')
  },
  registerRefund (data) {
    return this.execute('post', '/refunds', data)
  },
  deleteRefund (id) {
    return this.execute('delete', `/refunds/${id}`)
  },
  //orders 
  getOrders () {
    return this.execute('get', '/orders/pending')
  },
  //orders in
  getOrdersIn () {
    return this.execute('get', '/orders/in')
  },
  getOrderIn (id) {
    return this.execute('get', `/orders/in/${id}`)
  },
  registerOrderIn (data) {
    return this.execute('post', '/orders/in', data)
  },
  editOrderIn (id, data) {
    return this.execute('put', `/orders/in/${id}`, data)
  },
  editOrderItemIn (id, data) {
    return this.execute('patch', `/orders/in/${id}`, data)
  },
  deleteOrderIn (id) {
    return this.execute('delete', `/orders/in/${id}`)
  },
  //orders out
  getOrdersOut () {
    return this.execute('get', '/orders/out')
  },
  getOrderOut (id) {
    return this.execute('get', `/orders/out/${id}`)
  },
  registerOrderOut (data) {
    return this.execute('post', '/orders/out', data)
  },
  editOrderOut (id, data) {
    return this.execute('put', `/orders/out/${id}`, data)
  },
  editOrderItemOut (id, data) {
    return this.execute('patch', `/orders/out/${id}`, data)
  },
  deleteOrderOut (id, data) {
    return this.execute('delete', `/orders/out/${id}`, data)
  },
  //orders warehouse
  getOrdersWarehouse () {
    return this.execute('get', '/orders/warehouse')
  },
  getOrderWarehouse (id) {
    return this.execute('get', `/orders/warehouse/${id}`)
  },
  registerOrderWarehouse (data) {
    return this.execute('post', '/orders/warehouse', data)
  },
  editOrderWarehouse (id, data) {
    return this.execute('put', `/orders/warehouse/${id}`, data)
  },
  editOrderItemWarehouse (id, data) {
    return this.execute('patch', `/orders/warehouse/${id}`, data)
  },
  deleteOrderWarehouse (id) {
    return this.execute('delete', `/orders/warehouse/${id}`)
  },
  //orders product
  getOrdersProduct () {
    return this.execute('get', '/orders/product')
  },
  getOrderProduct (id) {
    return this.execute('get', `/orders/product/${id}`)
  },
  registerOrderProduct (data) {
    return this.execute('post', '/orders/product', data)
  },
  deleteOrderProduct (id) {
    return this.execute('delete', `/orders/product/${id}`)
  },
  //download upload
  downloadFile (type, id, filename) {
    return this.execute('get', `/orders/${type}/${id}/files/${filename}`)
  },
  //forecasts
  getForecastsResume () {
    return this.execute('get', '/forecasts/resume')
  },
  getForecasts () {
    return this.execute('get', '/forecasts')
  },
  getForecast (id) {
    return this.execute('get', `/forecasts/${id}`)
  },
  registerForecast (data) {
    return this.execute('post', '/forecasts', data)
  },
  editForecast (id, data) {
    return this.execute('put', `/forecasts/${id}`, data)
  },
  deleteForecast (id) {
    return this.execute('delete', `/forecasts/${id}`)
  },
  getRequestPurchases () {
    return this.execute('get', '/requests/purchase')
  },
  getRequestPurchase (id) {
    return this.execute('get', `/requests/purchase/${id}`)
  },
  getRequestSamples () {
    return this.execute('get', '/requests/sample')
  },
  getRequestSample (id) {
    return this.execute('get', `/requests/sample/${id}`)
  },
  getUserRequests () {
    return this.execute('get', `/requests/users`)
  },
  registerUserRequests (data) {
    return this.execute('post', `/requests/users`, data)
  },
  deletetUserRequests (id) {
    return this.execute('delete', `/requests/users/${id}`)
  },
  //file upload
  uploadFiles (type, id, formData) {
    return axios.patch(apiURL + `orders/${type}/${id}`, formData, 
    {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("userToken")}`,
        'Content-Type': 'multipart/form-data',
        'UserId': localStorage.getItem("userId")
      }
    })
  },
}